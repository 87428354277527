import React, { useState, useEffect } from "react";
import ReactSlider from "react-slider";
import tw, { styled } from "twin.macro";
import FixedContainer from "./FixedContainer";
import Chevron from "../assets/chevron.svg";

const Container = tw.div`
  bg-brand-green-light shadow text-white overflow-x-hidden
`;

const Header = tw.div`h-12 flex items-center`;

const NoScriptMessage = tw.div`px-2 py-1 text-sm`;

const HeaderButton = tw.button`w-full flex font-bold justify-between px-2 h-full items-center bg-black bg-opacity-0 hover:bg-opacity-25`;

const StyledChevron = styled(({ showFilters, ...rest }) => (
  <Chevron {...rest} />
))`
  ${tw`transform fill-current h-3`}
  ${({ showFilters }) => (showFilters ? tw`-rotate-90` : tw`rotate-90`)}
`;

const Body = styled.div`
  ${tw`p-2`}
  ${({ showFilters }) => !showFilters && tw`hidden`}
`;

const Filter = tw.div`not-last:mb-1`;

const FilterHeading = tw.div`font-bold text-xs`;

const StyledSlider = tw(ReactSlider)`w-full h-4 mt-1`;

const StyledThumb = styled.div`
  ${tw`h-4 w-4 leading-4 rounded-full bg-white shadow outline-none`}

  &.active {
    ${tw`ring`}
  }

  div {
    ${tw`opacity-0 absolute text-white text-center bg-black p-1 rounded text-xs bg-opacity-75 transition-opacity duration-300`}
    width: 4rem;
    left: calc(50% - 2rem);
    top: -2rem;

    &:before {
      ${tw`absolute w-0 h-0`}
      content: "";
      border-left: 5px solid transparent;
      border-top: 5px solid rgba(0, 0, 0, 0.75);
      border-right: 5px solid transparent;
      left: calc(50% - 5px);
      top: 100%;
    }
  }

  &:hover div,
  &:active div {
    ${tw`opacity-100`}
  }
`;

const Thumb = (props, state) => (
  <StyledThumb {...props}>
    <div>{state.valueNow} km</div>
  </StyledThumb>
);

const StyledTrack = styled.div`
  ${tw`top-0 m-1 rounded-full h-2`}
  ${({ index }) => (index === 1 ? tw`bg-brand-500` : tw`bg-gray-200`)}
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const SliderLabels = tw.div`flex justify-between text-xs mt-1 mb-2`;

const Tags = tw.div`mt-1`;

const Tag = styled.button`
  ${tw`uppercase p-1 text-xs font-bold rounded not-last:mr-2 mb-2`}
  ${({ active }) =>
    active
      ? tw`bg-white text-black border border-gray-400`
      : tw`bg-gray-200 text-gray-700 border border-gray-400`}
`;

const Filters = ({
  minDistance,
  maxDistance,
  tagFilters,
  filters,
  setFilters
}) => {
  const [enabled, setEnabled] = useState(false);
  const [showFilters, setShowFilters] = useState(true);

  useEffect(() => setEnabled(true), []);

  const getTagClickHandler = (type, tag) => () => {
    const active = filters[type] || [];
    const tagIndex = active.indexOf(tag);

    let newTags;

    if (tagIndex === -1) {
      newTags = [...active, tag];
    } else {
      newTags = [...active.slice(0, tagIndex), ...active.slice(tagIndex + 1)];
    }

    setFilters({ ...filters, [type]: newTags });
  };

  return (
    <Container>
      <FixedContainer>
        <Header>
          {enabled && (
            <HeaderButton onClick={() => setShowFilters(!showFilters)}>
              <div>Filter</div>
              <StyledChevron showFilters={showFilters} />
            </HeaderButton>
          )}
          <noscript>
            <NoScriptMessage>
              Please enable JavaScript to filter routes
            </NoScriptMessage>
          </noscript>
        </Header>
        {enabled && (
          <Body showFilters={showFilters}>
            <Filter>
              <FilterHeading>Distance</FilterHeading>
              <StyledSlider
                min={minDistance}
                max={maxDistance}
                renderTrack={Track}
                renderThumb={Thumb}
                minDistance={1}
                onChange={([minDistanceFilter, maxDistanceFilter]) => {
                  setFilters({
                    ...filters,
                    minDistance: minDistanceFilter,
                    maxDistance: maxDistanceFilter
                  });
                }}
                value={[filters.minDistance, filters.maxDistance]}
              />
              <SliderLabels>
                <div>{minDistance} km</div>
                <div>{maxDistance} km</div>
              </SliderLabels>
            </Filter>
            {Object.keys(tagFilters).map((type) => (
              <Filter key={type}>
                <FilterHeading>{type}</FilterHeading>
                <Tags>
                  {tagFilters[type].map((tag) => (
                    <Tag
                      key={tag}
                      active={
                        filters[type] && filters[type].indexOf(tag) !== -1
                      }
                      onClick={getTagClickHandler(type, tag)}
                    >
                      {tag}
                    </Tag>
                  ))}
                </Tags>
              </Filter>
            ))}
          </Body>
        )}
      </FixedContainer>
    </Container>
  );
};

export default Filters;
