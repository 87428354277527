import tw, { styled } from "twin.macro";

const FixedContainer = styled.div`
  ${tw`w-full mx-auto`}
  max-width: 800px;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
`;

export default FixedContainer;
