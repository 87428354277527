import React from "react";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import styled from "styled-components";
import tw from "twin.macro";

import PaddedContainer from "./PaddedContainer";

const StyledHero = styled.div`
  ${tw`relative overflow-hidden`}
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  ${tw`bg-bottom w-full`}
  height: 70vh;
  max-height: 200px;
  font-size: 10px;

  @media (min-width: 400px) {
    max-height: 400px;
  }

  @media (min-width: 600px) {
    max-height: 500px;
  }

  @media (min-width: 800px) {
    min-height: 450px;
    max-height: initial;
  }
`;

const Content = styled.div`
  ${tw`absolute top-0 h-full flex flex-col justify-center text-left max-w-xl font-bold text-2xl text-white`}
`;

const Title = styled.p`
  ${tw`text-4xl`}
`;

const Subtitle = styled.p`
  ${tw`mt-4 hidden sm:block`}
`;

const Hero = () => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "hero-background.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <StyledHero>
          <StyledBackgroundImage fluid={imageData} />
          <PaddedContainer>
            <Content>
              <Title>London's best cycle routes</Title>
              <Subtitle>
                A curated catalogue of two-wheeled adventures around the
                capital.
              </Subtitle>
              <Subtitle>Pedal somewhere new.</Subtitle>
            </Content>
          </PaddedContainer>
        </StyledHero>
      );
    }}
  />
);

export default Hero;
