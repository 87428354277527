import React from "react";
import { MAILCHIMP_ENDPOINT } from "../constants";
import tw, { styled } from "twin.macro";
import PaddedContainer from "./PaddedContainer";

const Banner = tw.div`bg-brand-green-dark`;

const Container = tw(
  PaddedContainer
)`py-2 flex justify-center items-center flex-wrap md:flex-nowrap`;

const Text = tw.div`text-sm text-brand-green-lighter font-bold text-center pb-2 md:pb-0`;

const InputContainer = styled.div`
  ${tw`bg-white rounded p-1 pl-2 flex text-xs h-8 ml-0 md:ml-4`};
  width: 280px;
`;

const EmailInput = tw.input`text-gray-700 placeholder-gray-800 min-w-0 flex-grow`;

const SubmitInput = tw.input`bg-brand-500 rounded text-white uppercase font-bold h-full px-4 cursor-pointer`;

const HiddenInputContainer = styled.div`
  ${tw`absolute`}
  left: -5000px;
`;

const AnnouncementBanner = () => (
  <Banner>
    <form
      action={MAILCHIMP_ENDPOINT}
      method="post"
      name="mc-embedded-subscribe-form"
      target="_blank"
      noValidate
    >
      <Container>
        <Text>Subscribe for new routes delivered straight to your inbox</Text>
        <InputContainer>
          <EmailInput type="email" name="EMAIL" placeholder="Email" required />
          <SubmitInput type="submit" value="Subscribe" name="subscribe" />
        </InputContainer>
        <HiddenInputContainer aria-hidden="true">
          <input
            type="text"
            name="b_e8430efd4e06a5e7e0a8df852_42dc1162ab"
            tabIndex="-1"
          />
        </HiddenInputContainer>
      </Container>
    </form>
  </Banner>
);

export default AnnouncementBanner;
