import React, { useState } from "react";
import { graphql } from "gatsby";
import tw from "twin.macro";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Hero from "../components/Hero";
import Filters from "../components/Filters";
import RoutesList from "../components/RoutesList";

import NewsletterBanner from "../components/NewsletterBanner";

const TAG_FILTERS = {
  Direction: ["north", "south", "east", "west", "central", "outside London"],
  Type: ["loop", "point-to-point"],
  Climbing: ["flat", "some hills", "all the hills"]
};

const RoutesCount = tw.div`text-center text-gray-700 font-bold text-xs uppercase my-4`;

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges }
  }
}) => {
  const routes = edges.map(({ node }) => node);

  const minDistance = Math.floor(
    Math.min(...routes.map((route) => route.frontmatter.file.childGpx.length))
  );

  const maxDistance = Math.ceil(
    Math.max(...routes.map((route) => route.frontmatter.file.childGpx.length))
  );

  const defaultFilters = {
    minDistance: 0,
    maxDistance
  };

  const [filters, setFilters] = useState(defaultFilters);

  let filteredRoutes = routes.filter(
    (route) =>
      route.frontmatter.file.childGpx.length >= filters.minDistance &&
      route.frontmatter.file.childGpx.length < filters.maxDistance &&
      Object.keys(TAG_FILTERS).every(
        (type) =>
          !filters[type] ||
          filters[type].length === 0 ||
          filters[type].some((tag) =>
            route.frontmatter.tags.some((routeTag) => routeTag === tag)
          )
      )
  );

  return (
    <Layout>
      <SEO />
      <div tw="h-full bg-gray-100">
        <Hero />
        <NewsletterBanner />
        <Filters
          minDistance={minDistance}
          maxDistance={maxDistance}
          tagFilters={TAG_FILTERS}
          filters={filters}
          setFilters={setFilters}
        />
        <RoutesCount>
          {filteredRoutes.length} route{filteredRoutes.length !== 1 ? "s" : ""}
        </RoutesCount>
        <RoutesList routes={filteredRoutes} />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___title }) {
      edges {
        node {
          id
          excerpt(pruneLength: 280)
          fields {
            path
          }
          frontmatter {
            title
            tags
            file {
              childGpx {
                length
                totalElevation
                mapImages(
                  input: [
                    { width: 800, height: 260 }
                    { width: 400, height: 130 }
                    { width: 600, height: 195 }
                  ]
                ) {
                  width
                  height
                  image2x {
                    childImageSharp {
                      gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
