import React from "react";
import tw, { styled, theme } from "twin.macro";
import { GatsbyImage, withArtDirection } from "gatsby-plugin-image";
import { YEAR } from "../constants";

const StaticMapContainer = styled.div`
  ${tw`relative`}

  .gatsby-image-wrapper-constrained {
    ${tw`block`}
  }
`;

const Attribution = tw.div`text-2xs absolute bottom-0 right-0 bg-white bg-opacity-50 px-1 text-gray-700`;

const StaticMap = ({ mapImages, alt }) => {
  const sortedImages = mapImages.sort((a, b) => b.width - a.width);

  const [mainImage, ...additionalImages] = sortedImages;

  return (
    <StaticMapContainer>
      <GatsbyImage
        image={withArtDirection(
          mainImage.image2x.childImageSharp.gatsbyImageData,
          additionalImages.reverse().map(({ width, image2x }) => ({
            media: `(max-width: ${width}px)`,
            image: image2x.childImageSharp.gatsbyImageData
          }))
        )}
        alt={alt}
        backgroundColor={theme`colors.map-green`}
      />
      <Attribution role="presentation">
        Contains OS data © Crown copyright and database right {YEAR}
      </Attribution>
    </StaticMapContainer>
  );
};

export default StaticMap;
