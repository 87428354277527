import React from "react";
import { Link } from "gatsby";
import StaticMap from "../components/StaticMap";
import Distance from "../assets/distance.svg";
import Elevation from "../assets/elevation.svg";

import "twin.macro";
import FixedContainer from "./FixedContainer";

const Route = ({ route }) => (
  <div tw="mb-8 bg-white shadow">
    <Link to={route.fields.path}>
      <StaticMap
        mapImages={route.frontmatter.file.childGpx.mapImages}
        alt={`Map of ${route.frontmatter.title} route`}
      />
    </Link>
    <div tw="p-3">
      <div tw="text-2xl truncate font-bold">
        <Link to={route.fields.path}>{route.frontmatter.title}</Link>
      </div>
      <div tw="flex text-gray-600 justify-between items-end font-bold">
        <div>
          <span>
            <Distance
              tw="inline-block mr-2 fill-current h-6 w-auto"
              role="img"
              aria-label="Distance"
            />
            {`${route.frontmatter.file.childGpx.length.toLocaleString([], {
              maximumFractionDigits: 1
            })} km`}
          </span>
          <span>
            <Elevation
              tw="inline-block ml-4 mr-2 fill-current h-5 w-auto"
              role="img"
              aria-label="Elevation"
            />
            {`${route.frontmatter.file.childGpx.totalElevation.toLocaleString(
              [],
              {
                maximumFractionDigits: 0
              }
            )} m`}
          </span>
        </div>
        <Link
          to={route.fields.path}
          tw="flex-shrink bg-brand-500 hover:bg-brand-400 text-xs text-white p-2 inline-flex items-center font-bold rounded uppercase"
        >
          View route
        </Link>
      </div>
      <div tw="border-t pt-2 mt-2">
        {route.excerpt}{" "}
        <Link to={route.fields.path} tw="text-brand-500 hover:text-brand-400">
          see more
        </Link>
      </div>
    </div>
  </div>
);

const RoutesList = ({ routes }) => (
  <FixedContainer>
    {routes.map((route) => (
      <Route key={route.fields.path} route={route} />
    ))}
  </FixedContainer>
);

export default RoutesList;
